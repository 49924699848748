<template>
  <div id="distribution-type-select">
    <v-combobox
      v-model="selectedDistributionType"
      :items="distributionTypeList"
      :filter="typeFilter"
      :rules="[required]"
      outlined
      label="Select Distribution Type"
      clearable
      dense
      @change="selectDistributionType(selectedDistributionType)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  props: {
    distributionType: {
      type: String,
      default: 'Regular',
    },
    filter: {
      type: Array,
      default: null,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { distributionTypeList } = useMedicationList()

    const selectedDistributionType = ref(props.distributionType)

    const typeFilter = filter => {
      filter.value.forEach(item => item)
    }

    const log = msg => {
      //console.log(msg)
    }

    const selectDistributionType = () => {
      emit('distribution-type-select', selectedDistributionType.value)
    }

    return {
      distributionTypeList,
      selectedDistributionType,

      required,
      log,

      selectDistributionType,
      typeFilter,
    }
  },
}
</script>
