<template>
  <div id="timing-select">
    <v-combobox
      v-model="selectedTiming"
      :items="timingList"
      outlined
      clearable
      dense
      item-text="description"
      :multiple="multiple"
      :label="label"
      :readonly="!isEdit"
      @change="selectTiming(selectedTiming)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  props: {
    scriptTiming: {
      type: Object,
      default: () => ({description: "Once Daily"}),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Select Timing',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { timingList, fetchScriptTimings } = useMedicationList()

    const log = msg => {
      //console.log(msg)
    }

    const selectedTiming = ref(null)

    const selectTiming = () => {
      emit('timing-select', selectedTiming.value)
    }


    return {
      timingList,
      selectedTiming,
      fetchScriptTimings,

      required,
      log,

      selectTiming,
    }
  },
  mounted() {
    this.fetchScriptTimings()

    this.$watch('timingList', () => {
      if (this.scriptTiming != null) {
        this.timingList.forEach(timing => {
          if ((timing.description === this.scriptTiming.description) || (timing.id === this.scriptTiming.id)) {
            this.selectedTiming = timing
          }
        })
      }
      this.selectTiming()
    })
  },
}
</script>
