<template>
  <div
    id="distribution-schedule-select"
  >
    <div>
      <v-col
        v-if="distType == 'Stat +' || distType == 'Stat'"
        cols="12"
        lg="3"
      >
        <med-time-select
          :med-times="selectedDistributionSchedule.statTime"
          :is-edit="isEdit"
          label="Stat Time"
          :multiple="false"
          @med-time-select="updateStatTime"
        ></med-time-select>
      </v-col>

      <div v-if="distType !== 'Stat'">
        <v-row>
          <v-col
            cols="12"
            lg="3"
          >
            <v-menu
              ref="menuref"
              v-model="menuID"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template
                v-slot:activator="{ on, attrs }"
                :readonly="!isEdit"
              >
                <v-text-field
                  v-model="startDate"
                  outlined
                  dense
                  clearable
                  :prepend-icon="icons.mdiCalendar"
                  label="Start Date"
                  hint="YYYY-MM-DD"
                  v-bind="attrs"
                  :readonly="!isEdit"
                  :rules="[required]"
                  v-on="on"
                  @change="selectDistributionSchedule(selectedDistributionSchedule)"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-if="isEdit"
                ref="picker"
                v-model="startDate"
                :show-current="true"
                :min="today"
                color="primary"
                @change="selectDistributionSchedule(selectedDistributionSchedule)"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            lg="3"
          >
            <v-menu
              ref="menuref"
              v-model="menuLO"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template
                v-slot:activator="{ on, attrs }"
                :readonly="!isEdit"
              >
                <v-text-field
                  v-model="stopDate"
                  outlined
                  dense
                  clearable
                  :prepend-icon="icons.mdiCalendar"
                  label="Stop Date"
                  hint="YYYY-MM-DD"
                  v-bind="attrs"
                  :readonly="!isEdit"
                  :rules="[required]"
                  v-on="on"
                  @change="selectDistributionSchedule(selectedDistributionSchedule)"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-if="isEdit"
                ref="picker"
                v-model="stopDate"
                :show-current="true"
                color="primary"
                :min="today"
                reactive
                @click="save"
                @change="selectDistributionSchedule(selectedDistributionSchedule)"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <med-time-select
              :med-times="selectedDistributionSchedule.medTimes"
              :is-edit="isEdit"
              :rules="[required]"
              label="Distribution Times"
              :multiple="true"
              @med-time-select="updateMedTimes"
            ></med-time-select>
          </v-col>

          <v-col
            cols="12"
            lg="3"
          >
            <med-time-select
              :med-times="selectedDistributionSchedule.startTime"
              :is-edit="isEdit"
              :rules="[required]"
              label="Start Time"
              :multiple="false"
              @med-time-select="updateStartTime"
            ></med-time-select>
          </v-col>

          <v-col
            cols="12"
            lg="3"
          >
            <med-time-select
              :med-times="selectedDistributionSchedule.stopTime"
              :is-edit="isEdit"
              :rules="[required]"
              label="Stop Time"
              :multiple="false"
              @med-time-select="updateStopTime"
            ></med-time-select>
          </v-col>

          <v-col
            cols="12"
            lg="2"
          >
            <v-switch
              v-model="selectedDistributionSchedule.regular"
              :readonly="!isEdit"
              color="primary"
              :value="selectedDistributionSchedule.regular == null ? true : selectedDistributionSchedule.regular"
              :label="selectedDistributionSchedule.regular ? 'Regular' : 'Irregular'"
              @change="selectDistributionSchedule(selectedDistributionSchedule)"
            >
            </v-switch>
          </v-col>
        </v-row>

        <v-row v-if="selectedDistributionSchedule.regular">
          <v-col
            cols="12"
            lg="3"
          >
            <v-select
              v-model="selectedDistributionSchedule.weeklyInterval"
              :readonly="!isEdit"
              :items="weeks"
              :value="schedule.weeklyInterval"
              dense
              outlined
              label="Weekly Interval"
              hint="1 = Every Week"
              @change="selectDistributionSchedule(selectedDistributionSchedule)"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            lg="9"
          >
            <v-select
              v-model="selectedDistributionSchedule.weekDays"
              :readonly="!isEdit"
              :items="days"
              :value="schedule.weekDays"
              multiple
              outlined
              dense
              label="Week Days"
              hint="Days for Distribution"
              @change="selectDistributionSchedule(selectedDistributionSchedule)"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col
            cols="12"
            lg="3"
          >
            <v-menu
              ref="menuref"
              v-model="menuIR"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template
                v-slot:activator="{ on, attrs }"
                :readonly="!isEdit"
              >
                <v-text-field
                  v-model="irregularSelection"
                  outlined
                  dense
                  clearable
                  :prepend-icon="icons.mdiCalendar"
                  label="Irregular Dates"
                  hint="YYYY-MM-DD"
                  v-bind="attrs"
                  :readonly="!isEdit"
                  :rules="[required]"
                  v-on="on"
                  @change="selectDistributionSchedule(selectedDistributionSchedule)"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-if="isEdit"
                ref="picker"
                v-model="irregularSelection"
                :show-current="true"
                :min="today"
                color="primary"
                multiple
                @click="save"
                @change="selectDistributionSchedule(selectedDistributionSchedule)"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import MedTimeSelect from '@/components/medication/MedTimeSelect.vue'
import { mdiClose, mdiCalendar } from '@mdi/js'
import CommonList from '@/store/CommonService'

export default {
  components: {
    MedTimeSelect,
  },
  props: {
    schedule: {
      type: Object,
      default: () => ({
        regular: true,
        weeklyInterval: 1,
        weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        stopDate: new Date(),
        startDate: new Date(),
        statTime: [],
        startTime: [],
        stopTime: [],
        irregularSelection: [],
        medTimes: [],
      }),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      weeks: [1, 2, 3, 4],
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      today: new Date().toISOString().substr(0, 10),
    }
  },
  watch: {
    type(value) {
      this.type = value
      //console.log(value)
    },
  },
  setup(props, { emit }) {
    const distType = ref(props.type)
    const selectedDistributionSchedule = ref(props.schedule)
    //console.log('Med Add', selectedDistributionSchedule)
    //console.log('Type', distType)

    const log = msg => {
      //console.log(msg)
    }

    const { formatDate } = CommonList()
    const stopDate = ref(formatDate(props.schedule.stopDate))
    const startDate = ref(formatDate(props.schedule.startDate))
    const irregularSelection = ref(formatDate(props.schedule.irregularSelection))

    const menuID = ref(false)
    const menuLO = ref(false)
    const menuIR = ref(false)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const selectDistributionSchedule = () => {
      if (selectedDistributionSchedule.value.regular == null || selectedDistributionSchedule.value.regular === false) {
        selectedDistributionSchedule.value.regular = false
        selectedDistributionSchedule.value.weekDays = null
        selectedDistributionSchedule.value.weeklyInterval = 0
      } else {
        selectedDistributionSchedule.value.irregularSelection = null
      }
      selectedDistributionSchedule.value.startDate = new Date(startDate.value)
      selectedDistributionSchedule.value.stopDate = new Date(stopDate.value)
      selectedDistributionSchedule.value.irregularSelection = irregularSelection

      //console.log('Selected Schedule', selectedDistributionSchedule.value)
      emit('distribution-schedule-select', selectedDistributionSchedule.value)
    }

    return {
      selectedDistributionSchedule,

      required,
      log,
      formatDate,
      stopDate,
      startDate,
      irregularSelection,

      menuID,
      menuLO,
      menuIR,
      menuref,
      save,
      distType,

      selectDistributionSchedule,
      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  mounted() {
    this.selectDistributionSchedule()
  },
  methods: {
    updateMedTimes(medTimes) {
      this.selectedDistributionSchedule.medTimes = medTimes
      //console.log('new MedTime', medTimes)
      //console.log('medTime update', this.selectedDistributionSchedule)
      this.selectDistributionSchedule()
    },
    updateStatTime(statTime) {
      this.selectedDistributionSchedule.statTime[0] = statTime.time
      //console.log('new statTime', statTime)
      //console.log('statTime update', this.selectedDistributionSchedule)
      this.selectDistributionSchedule()
    },
    updateStartTime(startTime) {
      this.selectedDistributionSchedule.startTime[0] = startTime.time
      //console.log('new startTime', startTime)
      //console.log('startTime update', this.selectedDistributionSchedule)
      this.selectDistributionSchedule()
    },
    updateStopTime(stopTime) {
      this.selectedDistributionSchedule.stopTime[0] = stopTime.time
      //console.log('new stopTime', stopTime)
      //console.log('stopTime update', this.selectedDistributionSchedule)
      this.selectDistributionSchedule()
    },
  },
}
</script>
