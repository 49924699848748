<template>
  <div id="prescriber-select">
    <v-combobox
      v-model="selectedPrescriber"
      :items="prescriberList"
      :rules="[required]"
      item-text="displayName"
      outlined
      label="Select Prescriber"
      clearable
      dense
      @change="selectPrescriber(selectedPrescriber)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationList from '@/store/useMedicationService'

export default {
  props: {
    prescriber: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) store.unregisterModule(MEDICATION_APP)
    })

    const { prescriberList, fetchSitePrescribers } = useMedicationList()

    const selectedPrescriber = ref(props.prescriber)

    const log = msg => {
      //console.log(msg)
    }

    const selectPrescriber = () => {
      emit('prescriber-select', selectedPrescriber.value)
    }

    return {
      prescriberList,
      selectedPrescriber,
      fetchSitePrescribers,

      required,
      log,

      selectPrescriber,
    }
  },
  mounted() {
    this.fetchSitePrescribers()
  },
}
</script>
